/*!

=========================================================
* Argon Design System React - v1.1.0 based on Argon Design System - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap Functions
@import "../../../node_modules/bootstrap/scss/functions";

// Theme Variables
@import "argon-design-system/variables";

// Bootstrap Core
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Theme Core
@import "argon-design-system/theme";

// React Differences
@import "react/react-differences";

html {
    overflow: scroll;
    overflow-x: hidden; }

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */ }

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000; }

.border-radius {
    border-radius: 20px !important;
    &-top {
        border-top-left-radius: 20px !important; 
        border-top-right-radius: 20px !important; 
    }
    &-bottom {
        border-bottom-left-radius: 20px !important; 
        border-bottom-right-radius: 20px !important; 
    }
}

.width-100 {
    width: 100%; }

.padding-bottom-null {
    padding-bottom: 10px !important; }

.padding-top-null {
    padding-top: 23px !important; }

.min-height400 {
    min-height: 400px !important; }

.min-height-develop {
    height: 68px !important; }

.min-height-news {
    min-height: 68px !important; }   
    
.height-100 {
    height: 100px; }

.padding-top-35 {
    padding-top: 35px;
    font-style: italic; 
    font-family: fantasy; 
    padding-left: 20px;
    padding-right: 20px; }

.padding-0 {
    padding: 0; }

.padding-bottom50 {
    padding-bottom: 50px;
}

.padding-top33 {
    padding-top: 33px;
}

.text-black-link {
    color: black !important; }

.text-yellow-light {
    color: rgb(248, 248, 229); }

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

.background-white {
    background: white !important; }

.shabloni-background {
    background-image: url('../img/brand/background4.gif');
    // background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.develop-background {
    // background-image: url('../img/brand/background1.gif');
    background-color: gainsboro !important;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }   

.prof-background {
    background-image: url('../img/brand/robot1.gif');
    margin-top: 80px !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; 
    // margin-top: -100px; 
}


// .text-shadow {
//     text-shadow: 1px 1px 2px white, 0 0 1em red; }

// .text-shadow-menu {
//     text-shadow: 1px 1px 2px white, 0 0 1em rgb(253, 79, 79); }

.h1_style_black {
    padding-left: 15px;
}

.text-white-relative {
   color: white;
   font-style: italic; }

.padding-50 {
    padding: 40px; }

.width-32 {
    width: 103px;
    float: right;
    margin-left: 10px;
    padding-right: 30px;
    margin-top: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.dropdown-lang {
    position: relative;
    margin-top: -1px;
    z-index: 10;
    margin-right: -6px;
}

.name-job {
    padding-bottom: 2rem;
}

.padding-bottom {
    padding-bottom: 2rem;
}

.btn-flag {
    height: 44px;
}

.doing-label {
    align-self: center;
    margin: 0 !important;
}

.img-country {
    width: 24px;
    height: 24px;
}

#tooltip38321129 {
    margin: 0;
    margin-left: -10px;
}