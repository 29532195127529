.h1_style {
    float: right;
    color: theme-color("white");
    font-weight: 700;
    padding-left: 5px;
    font-size: 30px;
    padding-top: 5px;
}

.h1_style_black {
    display: inline;
    position: absolute;
    color: theme-color("black");
    font-weight: 700;
    padding-left: 4px;
    font-size: 23px; }

.google_play_download {
    height: 50px; }

.google_play_button {
    border-width: 0; } 

.img-quant {
    height: 55px !important; }

.padding-none {
    padding: 0; }

.navbar-collapse.show, .navbar-collapse.collapsing {
    border-radius: 5px !important; }

///

.nav-link {
    color: $nav-link-color;

    &:hover,
    &.active {
      color: theme-color("primary");
    }

    i:not(.fa) {
        position: relative;
        top: 2px;
    }
}

// Pills

.nav-pills {
    .nav-item:not(:last-child) {
        padding-right: $nav-pills-space-x;
    }

    .nav-link {
    	padding: $nav-pills-padding-y $nav-pills-padding-x;
        color: $nav-pills-link-color;
        font-weight: 500;
        font-size: $font-size-sm;
        box-shadow: $nav-pills-box-shadow;
        background-color: $nav-pills-bg;
        transition: $transition-base;

        &:hover {
        	color: $nav-pills-link-hover-color;
        }
    }

    .nav-link.active,
    .show > .nav-link {
        color: $nav-pills-link-active-color;
        background-color: $nav-pills-link-active-bg;
    }

    @include media-breakpoint-down(xs) {
        .nav-item {
            margin-bottom: $spacer;
        }
    }
}

@include media-breakpoint-down(sm) {
    .nav-pills:not(.nav-pills-circle) {
        .nav-item {
            padding-right: 0;
        }
    }
}

// Nav pills circle

.nav-pills-circle {
    .nav-link {
        text-align: center;
        height: 60px;
        width: 60px;
        padding: 0;
        line-height: 60px;
        border-radius: 50%;
    }

    .nav-link-icon {
        i, svg {
            font-size: 1rem;
        }
    }
}

// Nav wrapper
.nav-wrapper {
    padding: 1rem 0;
    @include border-top-radius($card-border-radius);

    + .card {
        @include border-top-radius(0);
        @include border-bottom-radius($card-border-radius);
    }
}
